import React, { useState, useEffect } from "react";

import { joinWithHyphen } from "../../helpers/strings";

import { Button, Select } from "../Actionable/";
import CodeEditor from "../Swagger/CodeEditor";

const withDescriptionAndExample = (descriptionText, exampleText, children) => (
  <>
    {descriptionText}
    {children}
    {exampleText}
  </>
);

export const ParameterInputs = ({
  param,
  index,
  params,
  setEditParams,
  descriptionText,
  exampleText,
}) => {
  const [inputList, setInputList] = useState(
    param.value ? [param.value] : [""]
  );

  useEffect(() => {
    if (
      (param.value === undefined && !param.schema.default) ||
      param.schema.default === undefined
    ) {
      updateParamValue("");
    } else if (
      param.value === "" ||
      (param.value !== undefined && param.schema.default)
    ) {
      updateParamValue(param.schema.default);
    }
    // eslint-disable-next-line
  }, []);

  const updateParamValue = (value) => {
    value = value === "null" ? null : value;
    const updatedParams = [...params];
    updatedParams[index].value = value;
    setEditParams(updatedParams);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (param.schema.type === "integer") {
      value = value.replace(/[^0-9]/g, "");
      if (param.schema.maximum !== undefined && value > param.schema.maximum) {
        value = param.schema.maximum;
      }
    } else if (param.schema.type === "number") {
      if (param.schema.maximum !== undefined && value > param.schema.maximum) {
        value = param.schema.maximum;
      }
    }
    updateParamValue(value);
  };

  const handleInputBlur = (e) => {
    let value = e.target.value;
    if (param.schema.type === "integer" || param.schema.type === "number") {
      if (param.schema.minimum !== undefined && value < param.schema.minimum) {
        value = param.schema.minimum;
      }
    }
    updateParamValue(value);
  };

  const handleArrayInputChange = (e, i) => {
    let value = e.target.value;
    if (param.schema.items.type === "integer") {
      value = parseInt(value, 10);
    }
    const list = [...inputList];
    list[i] = value;
    setInputList(list);
    updateParamValue(list.join(","));
  };

  const handleRemoveClick = (i) => {
    const list = [...inputList];
    list.splice(i, 1);
    setInputList(list);
    updateParamValue(list.join(","));
  };

  const handleAddClick = () => {
    setInputList([...inputList, ""]);
  };

  const handleDeleteAllClick = () => {
    setInputList([""]);
    updateParamValue("");
  };

  const handleBooleanValueChange = (value) => {
    updateParamValue(value);
  };

  const borderStyle =
    param.required && !param.value
      ? "border-2 border-red-500"
      : "border-2 border-gray-300";
  const commonInputProps = {
    className: `p-[10px] min-w-[100px] w-[200px] rounded mt-[8px] dark:bg-[#272729] ${borderStyle}`,
    value: param.value,
    placeholder: param.name,
    onChange: handleInputChange,
    onBlur: handleInputBlur,
    required: param.required,
  };

  switch (param.schema.type) {
    case "string":
      if (param.schema.enum) {
        return withDescriptionAndExample(
          descriptionText,
          exampleText,
          <Select
            labelTitle={param.name}
            hideTitle={true}
            fixedWidth={true}
            onChange={(e) => updateParamValue(e.target.value)}
            selectList={param.schema.enum}
            required={param.required}
            value={param.value}
            nullableInputEnum={param.required !== true}
            {...commonInputProps}
          />
        );
      } else {
        return withDescriptionAndExample(
          descriptionText,
          exampleText,
          <input
            {...commonInputProps}
            maxLength={param.schema.maxLength}
            data-test={joinWithHyphen(param.name)}
          />
        );
      }
    case "integer":
      if (param.schema.enum) {
        return withDescriptionAndExample(
          descriptionText,
          exampleText,
          <Select
            labelTitle={param.name}
            hideTitle={true}
            fixedWidth={true}
            onChange={(e) => updateParamValue(e.target.value)}
            selectList={param.schema.enum}
            required={param.required}
            value={param.value}
            {...commonInputProps}
          />
        );
      } else {
        return withDescriptionAndExample(
          descriptionText,
          exampleText,
          <input
            {...commonInputProps}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            min={param.schema.minimum}
            max={param.schema.maximum}
          />
        );
      }
    case "number":
      return withDescriptionAndExample(
        descriptionText,
        exampleText,
        <input
          {...commonInputProps}
          type="number"
          step="any"
          min={param.schema.minimum}
          max={param.schema.maximum}
        />
      );
    case "array":
      return withDescriptionAndExample(
        descriptionText,
        exampleText,
        <>
          {inputList.map((x, i) => (
            <div className="d-flex align-items-center">
              <input
                {...commonInputProps}
                onBlur={(e) => handleArrayInputChange(e, i)}
                type={param.schema.items.type === "integer" ? "number" : "text"}
                value={x}
                onChange={(e) => handleArrayInputChange(e, i)}
                step={param.schema.items.type === "integer" ? "1" : "any"}
                min={param.schema.items.minimum}
                max={param.schema.items.maximum}
                data-test={`array-input-${i}`}
              />
              {inputList.length !== 1 && (
                <Button
                  className="mx-[6px] font-bold py-[6px] text-[20px]"
                  onClick={() => handleRemoveClick(i)}
                  data-test={`remove-array-input-${i}`}
                >
                  -
                </Button>
              )}
              {inputList.length - 1 === i && (
                <Button
                  className="mx-[6px] font-bold py-[6px]  text-[20px]"
                  data-test="add-array-input"
                  onClick={handleAddClick}
                >
                  +
                </Button>
              )}
            </div>
          ))}
          {inputList.length > 1 && (
            <Button
              className="mx-[4px] "
              onClick={handleDeleteAllClick}
              data-test="delete-all-array-input"
            >
              Delete All
            </Button>
          )}
        </>
      );
    case "boolean":
      return withDescriptionAndExample(
        descriptionText,
        exampleText,
        <Select
          labelTitle={param.name}
          onChange={(e) => handleBooleanValueChange(e.target.value === "true")}
          selectList={["true", "false"]}
          fixedWidth={true}
          required={param.required}
          value={param.value ? "true" : "false"}
        />
      );
    case "object":
      return withDescriptionAndExample(
        descriptionText,
        exampleText,
        <CodeEditor
          initialValue={param.schema.description.replace(/\s*Example:\s*/, "")}
        />
      );
    default:
      return withDescriptionAndExample(
        descriptionText,
        exampleText,
        <input
          {...commonInputProps}
          maxLength={param.schema.maxLength}
          data-test={joinWithHyphen(param.name)}
        />
      );
  }
};
