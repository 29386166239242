import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import {
  APPLICATION_TYPE,
  HEADER_PARAMETER_NAMES,
  HEADERS,
} from "../../constants";
import { DATA_TEST } from "../../constants/testing/dataTest";
import {
  getAuthHeaderStringAuthMethod,
  getDescriptionForAuthMethod,
  getExampleForAuthMethod,
} from "../../helpers/security";
import { hasValue, truncateString } from "../../helpers/strings";

import { ParameterInputs } from "./ParameterInputs";
import { Button, Select } from "../Actionable/";
import { TableMarkdownComponents } from "../Typography/Markdown";

const getDescriptionText = (param) => {
  if (param.description) {
    return param.description;
  }

  if (param.schema.description) {
    return `${param.schema.description}`;
  }

  return "";
};

const descriptionText = (param) => (
  <ReactMarkdown
    className="markdown-table"
    children={getDescriptionText(param)}
    components={TableMarkdownComponents}
    remarkPlugins={[remarkGfm]}
  />
);

const getAuthorizationHeader = (
  authMethod,
  basicAuth,
  authToken,
  authTokenRefresh,
  toggleAuthModal,
  tryItOut
) => {
  if (!authMethod) {
    return null;
  }

  const authHeaderString = getAuthHeaderStringAuthMethod(
    authMethod,
    basicAuth,
    authToken,
    authTokenRefresh
  );
  const description = descriptionText({
    description: getDescriptionForAuthMethod(authMethod),
  });

  return (
    <tr key={authMethod}>
      <td className="dpd-border-table dpd-text-code py-[8px] px-[16px]">
        authorization <span className="text-red-500 font-bold">*</span>
        <p className="pt-1">
          <strong>string</strong>
        </p>
        <p className="pt-1">
          <i>(header)</i>
        </p>
      </td>
      <td className="dpd-border-table dpd-text-light py-[8px] px-[16px]">
        {!tryItOut ? (
          <>
            {description}
            {getExampleForAuthMethod(authMethod)}
          </>
        ) : (
          <>
            {description}
            {truncateString(authHeaderString)}
          </>
        )}
      </td>
    </tr>
  );
};

const ParametersTable = ({
  params,
  setTryItOut,
  tryItOut,
  authToken,
  toggleModal,
  setEditParams,
  showParamErros,
  resetRequest,
  basicAuth,
  requestContentTypes,
  setRequestContentType,
  responseAcceptTypes,
  setResponseAcceptType,
  authMethod,
  authTokenRefresh,
}) => {
  const onTryItOut = () => {
    if (
      !getAuthHeaderStringAuthMethod(
        authMethod,
        basicAuth,
        authToken,
        authTokenRefresh
      )
    ) {
      return toggleModal();
    }

    if (tryItOut) {
      setTryItOut(false);
      return resetRequest();
    }

    setTryItOut(true);
  };

  const getExampleText = (param) => {
    let baseExample = "";

    if (hasValue(param?.example)) {
      baseExample += `Example: ${param.example}`;
    } else if (hasValue(param?.schema?.example)) {
      baseExample += `Example: ${param.schema.example}`;
    }

    if (param?.schema?.enum?.length > 0) {
      baseExample += `${baseExample ? "<br/>" : ""} Available values: ${param.schema.enum.join(", ")}`;
    }

    return baseExample;
  };

  const exampleText = (param) => (
    <p dangerouslySetInnerHTML={{ __html: getExampleText(param) }} />
  );

  const getMediaTypePicker = (
    types,
    setType,
    selectText,
    textInfo,
    tryItOut
  ) => {
    if (!types) {
      return null;
    }

    const description = descriptionText({
      description: textInfo,
    });
    return (
      <tr key={selectText}>
        <td className="dpd-border-table dpd-text-code py-[8px] px-[16px]">
          {selectText} <span className="text-red-500 font-bold">*</span>
          <p className="pt-1">
            <strong>string</strong>
          </p>
          <p className="pt-1">
            <i>(header)</i>
          </p>
        </td>
        <td className="dpd-border-table dpd-text-light py-[8px] px-[16px]">
          {!tryItOut ? (
            <>
              {description}
              {APPLICATION_TYPE.JSON}
            </>
          ) : (
            <>
              {description}
              <Select
                labelTitle={selectText}
                hideTitle={true}
                onChange={(e) => setType(e.target.value)}
                selectList={types}
              />
            </>
          )}
        </td>
      </tr>
    );
  };

  const generateRows = () => {
    return params.map((param, index) => {
      const showError = showParamErros && param.required && param?.value === "";
      const description = descriptionText(param);
      const example = exampleText(param);
      return (
        <tr key={param.name}>
          <td className="dpd-border-table dpd-text-code py-[8px] px-[16px]">
            {param.name}{" "}
            {param.required && (
              <span className="text-red-500 font-bold">*</span>
            )}
            <p className="pt-1">
              <strong>{param.schema.type}</strong>
            </p>
            <p className="pt-1">
              <i>({param.in})</i>
            </p>
          </td>
          <td className="dpd-border-table dpd-text-light py-[8px] px-[16px]">
            {!tryItOut ? (
              <>
                {description}
                {example}
              </>
            ) : (
              <>
                <ParameterInputs
                  param={param}
                  index={index}
                  params={params}
                  setEditParams={setEditParams}
                  showError={showError}
                  descriptionText={description}
                  exampleText={example}
                />
              </>
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="my-[24px]">
      <div className="flex">
        <h1 className="dpd-heading">Parameters</h1>
        <Button
          styleVariant={!tryItOut ? "redOutline" : "grayOutline"}
          className={"ml-auto rounded-md"}
          onClick={onTryItOut}
          data-test={DATA_TEST.PARAM_TRY_IT_OUT}
        >
          {!tryItOut ? "Try it out" : "Cancel"}
        </Button>
      </div>
      {params.length || responseAcceptTypes || requestContentTypes ? (
        <table className="mt-[16px] dpd-border-table w-full">
          <thead>
            <tr>
              <th className="dpd-text dpd-border-table w-[18%] text-left py-[8px] px-[16px]">
                Name
              </th>
              <th className="dpd-text dpd-border-table text-left py-[8px] px-[16px]">
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            {getAuthorizationHeader(
              authMethod,
              basicAuth,
              authToken,
              authTokenRefresh,
              toggleModal,
              tryItOut
            )}
            {getMediaTypePicker(
              requestContentTypes,
              setRequestContentType,
              HEADER_PARAMETER_NAMES[HEADERS["CONTENT-TYPE"]],
              "This controls the request content type",
              tryItOut
            )}
            {getMediaTypePicker(
              responseAcceptTypes,
              setResponseAcceptType,
              HEADER_PARAMETER_NAMES[HEADERS.ACCEPT],
              "This controls the returned response format",
              tryItOut
            )}
          </tbody>
          {params?.length ? <tbody>{generateRows(params)}</tbody> : ""}
        </table>
      ) : (
        <div className="mt-[16px] dpd-text">No parameters</div>
      )}
    </div>
  );
};

export default ParametersTable;

ParametersTable.propTypes = {
  params: PropTypes.array,
  setTryItOut: PropTypes.func,
  tryItOut: PropTypes.bool,
  authToken: PropTypes.string,
  toggleModal: PropTypes.func,
  setEditParams: PropTypes.func,
  showParamErrors: PropTypes.bool,
  resetRequest: PropTypes.func,
  basicAuth: PropTypes.string,
};
