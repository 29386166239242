import { SECTIONS as AUTH_SECTIONS } from "./auth/";
import { SECTIONS as COLLECTIONS_SECTIONS } from "./collections/";
import { SECTIONS as PARCELS_SECTIONS } from "./parcels/";
import { SECTIONS as PICKUP_SECTIONS } from "./pickup/";
import { SECTIONS as SHIPPING_SECTIONS } from "./shipping/";
import { PAGES } from "../";

const steps = {
  [PAGES.AUTH]: {
    sections: AUTH_SECTIONS,
  },
  [PAGES.SHIPPING]: {
    sections: SHIPPING_SECTIONS,
  },
  [PAGES.COLLECTIONS]: {
    sections: COLLECTIONS_SECTIONS,
  },
  [PAGES.PARCELS]: {
    sections: PARCELS_SECTIONS,
  },
  [PAGES.PICKUP]: {
    sections: PICKUP_SECTIONS,
  },
};

export default steps;
