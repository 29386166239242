import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";

import { DATA_TEST } from "../../constants/testing/dataTest";
import { buildRequest } from "../../helpers/request";
import { getAuthHeader } from "../../helpers/security";
import { joinWithHyphen, objectToCurlCommand } from "../../helpers/strings";

import { Button } from "../Actionable/";
import Spinner from "../Spinner";
import RequestBody from "../Swagger/RequestBody";
import ParametersTable from "../Tables/ParametersTable";
import RequestTable from "../Tables/RequestTable";
import { MarkdownComponents } from "../Typography/Markdown";
import ParamError from "../Typography/ParamError";

const SwaggerSection = ({
  summary,
  description,
  params,
  requestBodyExample,
  method,
  authToken,
  toggleModal,
  urlPath,
  basicAuth,
  authMethod,
  authTokenRefresh,
  requestContentTypes,
  responseAcceptTypes,
}) => {
  const [allRequiredParamsFilled, setAllRequiredParamsFilled] = useState(false);
  const [tryItOut, setTryItOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editBody, setEditBody] = useState("");
  const [networkError, setNetworkError] = useState(false);
  const [editParams, setEditParams] = useState([]);
  const [showParamErros, setShowParamErros] = useState(false);
  const [curlString, setCurlString] = useState("");
  const [fullURLString, setFullURLString] = useState("");
  const [requestResponseMessage, setRequestResponseMessage] = useState("");
  const [requestResponseStatus, setRequestResponseStatus] = useState("");
  const [requestResponseHeaders, setRequestResponseHeaders] = useState({});
  const [showBodyErrors, setShowBodyErrors] = useState(false);
  const [requestContentType, setRequestContentType] = useState(
    requestContentTypes?.length ? requestContentTypes[0] : null // if we have a GET we dont use accept APPLICATION_TYPE.JSON
  );
  const [responseAcceptType, setResponseAcceptType] = useState(
    responseAcceptTypes[0] // we always have something here if we dont this is bad - see swagger transformer
  );
  const [selectedRequestExample, setSelectedRequestExample] = useState("");

  const { pathname } = useLocation();

  useEffect(() => {
    resetRequest();
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    const allFilled = editParams.every(
      (param) => !param.required || param.value !== ""
    );
    setAllRequiredParamsFilled(allFilled);
  }, [editParams]);

  useEffect(() => {
    if (
      requestBodyExample &&
      (requestBodyExample[requestContentType]?.examples ||
        requestBodyExample[requestContentType]?.example)
    ) {
      setEditBody(
        JSON.stringify(
          requestBodyExample[requestContentType]?.examples ||
            requestBodyExample[requestContentType]?.example,
          null,
          2
        )
      );
    }
    if (params && !requestContentTypes) {
      const updatedParams = params.map((param) => ({
        ...param,
        value: param.value || "",
      }));
      setEditParams(updatedParams);
    }
  }, [requestBodyExample, params, requestContentType, requestContentTypes]);

  const resetRequest = () => {
    setShowBodyErrors(false);
    const resetParams = params.map((param) => {
      param.value = "";
      return param;
    });

    setEditParams(resetParams);

    if (
      selectedRequestExample &&
      requestBodyExample[requestContentType]?.examples
    ) {
      setEditBody(
        JSON.stringify(
          requestBodyExample[requestContentType]?.examples[
            selectedRequestExample
          ].value,
          null,
          2
        )
      );
    } else if (
      requestBodyExample &&
      requestBodyExample[requestContentType]?.example
    ) {
      setEditBody(
        JSON.stringify(requestBodyExample[requestContentType]?.example, null, 2)
      );
    } else {
      setEditBody("");
    }
    setShowParamErros(false);
    setCurlString("");
    setFullURLString("");
    setRequestResponseMessage("");
    setRequestResponseStatus("");
    setRequestResponseHeaders({});
  };

  const handleOnClick = async () => {
    setShowParamErros(false);
    setShowBodyErrors(false);

    const isAuthedIfRequired = authMethod
      ? !!getAuthHeader(authMethod, basicAuth, authToken, authTokenRefresh)
      : false;
    if (!isAuthedIfRequired) {
      return toggleModal();
    }

    editParams.forEach((param) => {
      if (param.required && param.value === "") {
        return setShowParamErros(true);
      }
    });

    try {
      setLoading(true);
      const { curlCommand, url, headers } = objectToCurlCommand({
        urlPath,
        authToken,
        editParams,
        editBody,
        method,
        basicAuth,
        authTokenRefresh,
        authMethod,
        requestContentType,
        responseAcceptType,
      });
      setCurlString(curlCommand);
      setFullURLString(url);
      const requestData = buildRequest(
        method,
        url,
        authToken,
        editBody,
        headers,
        basicAuth,
        authTokenRefresh,
        authMethod,
        requestContentType,
        responseAcceptType
      );
      const {
        data,
        status,
        headers: responseHeaders,
      } = await axios(requestData);
      setRequestResponseMessage(JSON.stringify(data, null, 2));
      setRequestResponseStatus(status);
      setRequestResponseHeaders(responseHeaders);
    } catch (error) {
      if (error.response) {
        const resError = error.response.data
          ? error.response.data
          : "Unknown Error";
        const statusError = error.response.status;
        setRequestResponseMessage(JSON.stringify(resError, null, 2));
        setRequestResponseStatus(statusError);
        setRequestResponseHeaders(error?.response?.headers);
      } else if (error.message === "Network Error") {
        setNetworkError(true);
      } else {
        setShowBodyErrors(true);
        setCurlString("");
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading && <Spinner />}
      <section
        id={joinWithHyphen(summary)}
        className="flex  space-x-4"
        data-test={`${DATA_TEST.SWAGGER_SECTION}-${joinWithHyphen(summary)}`}
      >
        <div className="w-full overflow-auto z-20 ">
          <div className="flex items-center ">
            <h1 className="dpd-heading py-2">Try it out</h1>
          </div>
          <div className="dpd-border-full">
            <div className="px-6">
              {(params || requestContentTypes || responseAcceptTypes) && (
                <>
                  <ParametersTable
                    params={editParams}
                    setTryItOut={setTryItOut}
                    tryItOut={tryItOut}
                    authToken={authToken}
                    toggleModal={toggleModal}
                    setEditParams={setEditParams}
                    showParamErros={showParamErros}
                    resetRequest={resetRequest}
                    basicAuth={basicAuth}
                    requestContentTypes={requestContentTypes}
                    setRequestContentType={setRequestContentType}
                    responseAcceptTypes={responseAcceptTypes}
                    setResponseAcceptType={setResponseAcceptType}
                    authMethod={authMethod}
                    authTokenRefresh={authTokenRefresh}
                  />
                </>
              )}
              {tryItOut && (
                <React.Fragment>
                  <div className="flex justify-between">
                    <Button
                      className="w-[49%]"
                      styleVariant={
                        allRequiredParamsFilled ? "redSolid" : "disabledSolid"
                      }
                      onClick={handleOnClick}
                      disabled={!allRequiredParamsFilled}
                      data-test={DATA_TEST.TRY_IT_OUT_EXECUTE}
                    >
                      Execute
                    </Button>
                    <Button
                      className="w-[49%]"
                      styleVariant={"grayOutline"}
                      onClick={resetRequest}
                      data-test={DATA_TEST.TRY_IT_OUT_CLEAR}
                    >
                      Clear
                    </Button>
                  </div>
                  {showParamErros && showBodyErrors && (
                    <ParamError
                      errors={[
                        "Required parameters are not provided",
                        "Invalid JSON body",
                      ]}
                    />
                  )}
                  {showParamErros && !showBodyErrors && (
                    <ParamError
                      errors={["Required parameters are not provided"]}
                    />
                  )}
                  {networkError && (
                    <ParamError
                      errors={["Network error. Please try again later."]}
                    />
                  )}
                  {showBodyErrors && !showParamErros && (
                    <ParamError errors={["Invalid JSON body"]} />
                  )}
                </React.Fragment>
              )}
              {requestBodyExample && (
                <RequestBody
                  body={editBody}
                  tryItOut={tryItOut}
                  setEditBody={setEditBody}
                  multipleExamples={
                    requestBodyExample?.[requestContentType]?.examples
                  }
                  selectedRequestExample={selectedRequestExample}
                  setSelectedRequestExample={setSelectedRequestExample}
                />
              )}

              {curlString && (
                <RequestTable
                  curlCommand={curlString}
                  fullURLString={fullURLString}
                  requestResponseMessage={requestResponseMessage}
                  requestResponseStatus={requestResponseStatus}
                  requestResponseHeaders={requestResponseHeaders}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SwaggerSection;

SwaggerSection.propTypes = {
  summary: PropTypes.string,
  description: PropTypes.string,
  params: PropTypes.array,
  requestBodyExample: PropTypes.object,
  schema: PropTypes.object,
  responses: PropTypes.array,
  method: PropTypes.string,
  authToken: PropTypes.string,
  toggleModal: PropTypes.func,
  urlPath: PropTypes.string,
  basicAuth: PropTypes.string,
  authMethod: PropTypes.string,
  authTokenRefresh: PropTypes.string,
  requestContentTypes: PropTypes.array,
};
