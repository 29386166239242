import PropTypes from "prop-types";
import { lazy, useState, Suspense } from "react";

import {
  HEADER_PARAMETER_NAMES,
  HEADERS,
  PRINTER_TYPES,
} from "../../constants";
import { DATA_TEST } from "../../constants/testing/dataTest";

import Spinner from "../Spinner";
const CodeViewer = lazy(() => import("../Typography/CodeViewer"));
import JsonBlock from "../Typography/JsonBlock";
import CodeBlock from "../Typography/Markdown/CodeBlock";
import MessageCard from "../Typography/MessageCard";

const getFormattedResponse = (responseHeaders, responseMessage) => {
  const responseContentType =
    responseHeaders?.[HEADER_PARAMETER_NAMES[HEADERS["CONTENT-TYPE"]]];
  if (
    !responseContentType ||
    !PRINTER_TYPES.some((element) => responseContentType.includes(element))
  ) {
    return responseMessage;
  }

  try {
    return JSON.parse(responseMessage);
  } catch (e) {
    return responseMessage;
  }
};

const RequestTable = ({
  curlCommand,
  fullURLString,
  requestResponseMessage,
  requestResponseStatus,
  requestResponseHeaders,
  showHeader = true,
}) => {
  return (
    <div className="my-[24px] w-full">
      {showHeader && <h1 className="dpd-heading">Request</h1>}
      <MessageCard
        messageType="warning"
        message="Demo code sample. Not intended for production use."
      />
      <div data-test={DATA_TEST.REQUEST_OUTPUT}>
        <Suspense fallback={<Spinner inline={true} />}>
          <CodeViewer curlCommand={curlCommand} />
        </Suspense>
      </div>
      {fullURLString && (
        <>
          <h3 className="dpd-text mt-[16px]">Request URL</h3>
          <div data-test={DATA_TEST.REQUEST_URL}>
            <CodeBlock inline={false} language={"curl"} value={fullURLString} />
          </div>
        </>
      )}
      {requestResponseMessage && (
        <>
          <h3 className="dpd-text mt-[16px]">Request Response</h3>
          <table className="mt-[16px] dpd-border-table w-full">
            <thead>
              <tr>
                <th className="dpd-text dpd-border-table w-[10%] text-left py-[8px] px-[16px]">
                  Code
                </th>
                <th className="dpd-text dpd-border-table text-left py-[8px] px-[16px]">
                  Details
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className="dpd-border-table dpd-text-code text-center py-[8px] px-[16px]"
                  data-test={DATA_TEST.RESPONSE_STATUS}
                >
                  {requestResponseStatus}
                </td>
                <td
                  className="dpd-border-table dpd-text-light py-[8px] px-[16px]"
                  data-test={DATA_TEST.RESPONSE_OUTPUT}
                >
                  <JsonBlock
                    value={getFormattedResponse(
                      requestResponseHeaders,
                      requestResponseMessage
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default RequestTable;

RequestTable.propTypes = {
  curlCommand: PropTypes.string,
  fullURLString: PropTypes.string,
  requestResponseMessage: PropTypes.string,
  requestResponseStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
