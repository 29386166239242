import React from "react";

import { PAGES, PATHS } from "../../index";

import { Link } from "../../../components/Actionable/";
import SVGViewer from "../../../components/Actionable/SVGViewer";
import { JsonBlock } from "../../../components/Typography";
import BodyParagraph from "../../../components/Typography/BodyParagraph";
import MessageCard from "../../../components/Typography/MessageCard";
import { withCachebuster } from "../../../helpers/cache";
import { sanitisePath } from "../../../helpers/strings";
import { ERROR_HANDLING as AUTH_ERROR_HANDLING } from "../auth/sections";
import getAvailableEndpoints from "../helpers/page";

export const INTRODUCTION = {
  summary: "Introduction",
  body: (
    <>
      <BodyParagraph>
        The "Pickup Locations" API provides multiple endpoints to retrieve
        pickup location data based on different search criteria. These APIs
        enable integration with internal systems to locate pickup points
        efficiently.
      </BodyParagraph>
      <BodyParagraph>The available APIs include:</BodyParagraph>
      <BodyParagraph>
        Get Pickup Location by Shop ID: Retrieve details of a specific pickup
        location using its unique shop identifier. This allows direct lookup of
        a known pickup point.
      </BodyParagraph>
      <BodyParagraph>
        Get Pickup Location by Address: Search for pickup locations using a
        structured address. The API returns matching locations based on the
        provided address details.
      </BodyParagraph>
      <BodyParagraph>
        Get Pickup Location by Latitude and Longitude: Identify nearby pickup
        locations by providing geographic coordinates (latitude and longitude).
        The API returns the closest available locations.
      </BodyParagraph>
      <BodyParagraph>
        Get Pickup Locations File (CSV or JSON): Retrieve a full dataset of
        available pickup locations in CSV or JSON format. This allows for
        system-wide integration, bulk processing, or offline analysis of pickup
        locations.
      </BodyParagraph>
      <BodyParagraph>
        Get Pickup Categories File (CSV or JSON): Retrieve a categorized dataset
        of pickup locations in CSV or JSON format. This provides structured
        classification of pickup points based on predefined categories.
      </BodyParagraph>
      <BodyParagraph>
        These APIs are designed to provide comprehensive access to pickup
        location data, improving visibility and enabling seamless integration
        into logistics and e-commerce platforms.
      </BodyParagraph>
      <BodyParagraph>
        Explore the technical documentation for our available endpoints below:
      </BodyParagraph>
      <BodyParagraph>{getAvailableEndpoints(PAGES.PICKUP)}</BodyParagraph>
    </>
  ),
};

export const FLOW_INTEGRATIONS = {
  summary: "Integration",
  body: (
    <>
      <MessageCard
        messageType={"note"}
        message={
          <div style={{ wordWrap: "break-word" }}>
            In order to use pickup endpoints there is a prerequisite to have
            integrated with DPD UK authentication API’s in order to authenticate
            with Pickup API’s. Please refer to our{" "}
            <Link to={`/${PAGES.AUTH}${PATHS.INTRODUCTION}`}>
              auth specification
            </Link>{" "}
            for more information.
          </div>
        }
      />
      <BodyParagraph>Example flow to access our pickup data:</BodyParagraph>
      <SVGViewer src={withCachebuster("/images/svg/PickupFlow.svg")} />
      <BodyParagraph>Example flow to get a pickup file:</BodyParagraph>
      <SVGViewer src={withCachebuster("/images/svg/PickupFileFlow.svg")} />
    </>
  ),
};

export const ERROR_HANDLING = {
  summary: "Error Handling",
  body: (
    <>
      <MessageCard
        messageType="note"
        message="Pickup endpoints can return both authentication based errors and also pickup related errors, the response schema of Authentication related errors and pickup related errors differ so you will need to be able to cater for both types of error responses."
      />
      <BodyParagraph>
        To see details of authentication based errors please visit our{" "}
        <Link
          to={`/${PAGES.AUTH}${PATHS.INTRODUCTION}/#${sanitisePath(AUTH_ERROR_HANDLING.summary)}`}
        >
          Authentication specification
        </Link>{" "}
        for more information.
      </BodyParagraph>
      <BodyParagraph.Header>
        Examples of common Pickup Errors
      </BodyParagraph.Header>
      <BodyParagraph>
        Please see types of examples of error codes that can be received, these
        are categorized by code/s types.
      </BodyParagraph>
      <BodyParagraph>
        <div className="markdown-table overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr>
                <th>type</th>
                <th>code</th>
                <th>status</th>
                <th>description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>System</td>
                <td>100</td>
                <td>400</td>
                <td>System errors (e.g database error)</td>
              </tr>
              <tr>
                <td>Application</td>
                <td>101</td>
                <td>400</td>
                <td>User rule errors (e.g access to pickup apis disabled)</td>
              </tr>
              <tr>
                <td>Business</td>
                <td>102</td>
                <td>400</td>
                <td>Business rule errors</td>
              </tr>
              <tr>
                <td>FieldValidation</td>
                <td>103</td>
                <td>400</td>
                <td>Field validation (e.g max length exceeded)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BodyParagraph>
      <BodyParagraph>
        TODO:replace Detailed error information is provided within the message
        field included within the response to describe the issue with the
        request.
      </BodyParagraph>
      <BodyParagraph>
        TODO:replace For example : "message": "\"format\" must be one of [jpeg,
        png]",
      </BodyParagraph>
      <BodyParagraph>
        TODO:replace The fieldName and fieldpath also provides the affected
        fieldName where the issue has been detected and also the fieldPath gives
        the object / array on where the fieldName is located within the JSON
        request.
      </BodyParagraph>
      <BodyParagraph>"fieldName": "format"</BodyParagraph>
      <BodyParagraph>"fieldPath": "format"</BodyParagraph>
      {/* TODO:replace */}
      <JsonBlock
        value={JSON.stringify(
          {
            error: [
              {
                code: 103,
                links: {
                  self: "/v1/customer/parcel/15501123124724*20765/image?format=svg&imageKey=0030*19539*707*47460*2*001*0*1&imageType=S5",
                },
                status: 400,
                fieldName: "format",
                fieldPath: "format",
                displayType: "",
                rule: "",
                timestamp: 1707239329794,
                type: "FieldValidation",
                message: '"format" must be one of [jpeg, png]',
                id: "b57e7b9dd0449ef09fc6759fc7390eb0",
              },
            ],
          },
          null,
          2
        )}
      />

      <BodyParagraph>
        <ul>
          <li>
            <b>System Errors (code 100):</b>
          </li>
          <ul className={"list-disc"}>
            <li>Using the wrong ID when performing actions on a resource</li>
          </ul>
          <br />
          <li>
            <b>Application Errors (code 101):</b>
          </li>
          <ul className={"list-disc"}>
            <li>
              User role specific errors (e.g disabling creation of pickup apis
              via the customer portal)
            </li>
          </ul>
          <br />
          <li>
            <b>Business Errors (code 102):</b>
          </li>
          <ul className={"list-disc"}>
            <li>
              This account is on-stop - please contact your account manager if
              this occurs
            </li>
          </ul>
          <br />
          <li>
            <b>Field Validation Errors (code 103):</b>
          </li>
          <ul className={"list-disc"}>
            <li>Missing mandatory fields</li>
            <li>Min/Max length exceeded</li>
            <li>Min/Max value exceeded</li>
            <li>Invalid format or type</li>
          </ul>
        </ul>
        <br />
      </BodyParagraph>
    </>
  ),
};
